<template>
  <div class="bigDIv" @click="visible=true">
    <!-- <div v-if="show" class="animate__animated animate__slideOutUp class" :key="currentIndex">{{ currentItem }}领取了课程</div> -->
    <img src="https://oss.hshwhkj.com/hshcrm/image/2024/07/30/172232865252827639.jpg" alt="">
    <img src="https://oss.hshwhkj.com/hshcrm/image/2024/07/30/172232866912178955.jpg" alt="">
    <img src="https://oss.hshwhkj.com/hshcrm/image/2024/07/30/172232868540652023.jpg" alt="">
    <img src="https://oss.hshwhkj.com/hshcrm/image/2024/07/30/17223287019811030.jpg" alt="">
    <img src="https://oss.hshwhkj.com/hshcrm/image/2024/07/30/172232871898625810.jpg" alt="">
    <img src="https://oss.hshwhkj.com/hshcrm/image/2024/07/30/172230660833736013.gif" alt="" class="fixdBto" @click="throttleSubmit">

  </div>
</template>

<script>
import 'animate.css';
import { getWxCsad } from "../../api/360AD.js";

export default {
  name: '',
  props: {
  },
  components: {

  },
  data () {
    return {
      ruleForm: {

        land_link: "", //落地页链接
      },
      items: [' 高***灵8秒前', ' 王***西17秒前', ' 李***定23秒前', ' 钱**林39秒前', ' 冼***星一分钟前'], // 数组中的项
      text: ['高***康', '王***西', '李***明', '张***明'],
      currentIndex: 0, // 当前显示的项的索引
      currentItem: '', // 当前显示的项
      show: true // 控制项的显示和隐藏
    }
  },
  created () {
    this.ruleForm.land_link = window.location.href

  },
  methods: {



    throttleSubmit () {
      const currentTime = Date.now();
      if (currentTime - this.lastClickTime < 2500) {
        return; // 在一秒内多次点击，直接返回
      }
      this.lastClickTime = currentTime; // 更新上次点击时间

      this.wxClick()
    },
    async wxClick () {
      const { data } = await getWxCsad(this.ruleForm);
      window.location.href = data.data;
    },
  },
  mounted () {
    // this.startTimer();
  },
  watch: {

  },
  computed: {

  },
  filters: {

  }
}
</script>

<style scoped lang='less'>
.bigDIv {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  .wx {
    position: fixed;
    top: 50%;
    right: 10px;
    width: 48px;
    height: 48px;
    object-fit: contain;
  }
  img {
    width: 100%;
  }
  .fild {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 1s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
  }
  .class {
    font-size: 24px;
    text-align: center;
    position: absolute;
    right: 20px;
    top: 40px;
    font-size: 12px;
    width: 150px;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    border-radius: 50px;
    line-height: 30px;
    text-align: center;
  }
  .btn {
    margin-bottom: 50px;
  }
  .fixdBto {
    position: fixed;
    bottom: 0;
  }
  .animate__animated.animate__slideOutUp {
    --animate-duration: 3s;
  }
}
@media screen and (min-width: 750px) {
  .bigDIv {
    width: 750px;
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    img {
      width: 100%;
    }
    .wx {
      position: fixed;
      top: 50%;
      right: 25%;
      width: 48px;
      height: 48px;
      object-fit: contain;
    }
    .fild {
      position: absolute;
      top: 20px;
      right: 20px;
    }
    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 1s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
      opacity: 0;
    }
    .class {
      font-size: 24px;
      text-align: center;
      position: absolute;
      right: 20px;
      top: 40px;
      font-size: 12px;
      width: 150px;
      height: 30px;
      background-color: rgba(0, 0, 0, 0.6);
      color: #fff;
      border-radius: 50px;
      line-height: 30px;
      text-align: center;
    }
    .btn {
      margin-bottom: 150px;
    }
    .fixdBto {
      position: fixed;
      width: 750px;

      bottom: 0;
    }
    .animate__animated.animate__slideOutUp {
      --animate-duration: 3s;
    }
  }
}
</style>